<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col class="red--text text-center" cols="12">
        <h1>Page Not Found</h1>
        <p>Go back to home page.</p>
        <v-btn
          color="red darken-1"
          dark
          fab
          href="https://www.chapeldragonfly.com/"
        >
          <v-icon> mdi-home </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>